._404ErrorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.5rem;
    height: calc(100vh - 70px) !important;
}

._404ErrorText {
    font-weight: 500;
    text-align: center;
    font-size: 2.2rem;
}

._404ErrorImage {
    width: 40rem;
    height: 20rem;
}
