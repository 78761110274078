.container {
    display: flex;
    justify-content: space-evenly;
}

.padded-container {
    padding: 20px;
}

.container__sidemenu-wrapper {
    display: flex;
    width: 130px;
}

.container__sidemenu,
.container__sidemenu-accent {
    display: flex;
    height: 100%;
    align-items: center;
    flex: 0.2 1;
    position: fixed;
    z-index: 1;
    top: 8%;
    overflow-x: hidden;
}

.sub_wrapper {
    display: flex;
    position: fixed;
    height: -webkit-fill-available;
    height: 100%;
    align-items: center;
}

.container__sidemenu li > img,
.container__sidemenu-accent li > img,
.container__sidemenu-wrapper li > img {
    height: 2.4rem;
}

.container__sidemenu > ul,
.container__sidemenu-wrapper > ul {
    padding: 10px 0px;
    background-color: #101941;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    align-self: center;
    width: 130px;
}

.container__sidemenu li,
.container__sidemenu-wrapper li {
    padding: 15px 5px !important;
    font-size: 1rem !important;
    color: #b7bac6 !important;
    text-align: center !important;
    cursor: pointer !important;
    margin: 5px 0px !important;
}

.container__sidemenu li.active,
.container__sidemenu-wrapper li.active {
    background: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
    color: #ffffff !important;
    font-weight: 700;
}

.container__sidemenu li:hover,
.container__sidemenu-wrapper li:hover {
    background: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
    color: #ffffff !important;
    font-weight: 700;
}

/* sub */

.sub_wrapper li > img {
    height: 2.4rem;
}

.sub_wrapper > ul {
    padding: 10px 0px;
    background-color: #101941;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    /* align-self: unset; */
    width: 130px;
    /* position: sticky; */
    height: fit-content;
    margin-top: -150px;
}

.sub_wrapper li {
    padding: 15px 5px;
    font-size: 1rem !important;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 5px 0px;
}

.sub_wrapper li.active {
    background-color: #ff914d;
}

.sub_wrapper li:hover {
    background-color: #ff914d;
}
/* accent */
.sub_wrapper-accent {
    display: flex;
    /* background-color: red; */
    position: fixed;
    /* align-items: flex-start; */
    align-items: center;
    height: 100%;
}
.sub_wrapper-accent li > img {
    height: 2.4rem;
}

.sub_wrapper-accent > ul {
    padding: 10px 0px;
    background-color: #101941;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    /* align-self: unset; */
    width: 130px;
    /* position: sticky; */
    height: fit-content;
    margin-top: -120px;
}

.sub_wrapper-accent li {
    padding: 15px 5px;
    font-size: 1.2rem;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 5px 0px;
}

.sub_wrapper-accent li.active {
    background: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
}

.sub_wrapper-accent li:hover {
    background-color: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
}

/* /sub */

.container__sidemenu-accent > ul {
    padding: 10px 0px;
    background-color: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    align-self: center;
    width: 130px;
}

.container__sidemenu-accent li {
    padding: 15px 5px;
    font-size: 1.2rem;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 5px 0px;
}

.container__sidemenu-accent li.active {
    background: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
}

.container__sidemenu-accent li:hover {
    background: linear-gradient(180deg, #fc441e 0%, #fe5567 100%);
}

.container__topmenu {
    /* background-color: red; */
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

/* .container__content{
    overflow-y: scroll;
    height: calc(100vh - 186px);
    padding: 10px;
} */

.container__nav {
    background-color: #101941;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    flex: 0.9;
}

.container__nav-full {
    background-color: #101941;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex: 1;
}

.container__nav > ul,
.container__nav-full > ul {
    list-style: none;
    color: white;
    display: flex;
    flex-direction: row;
    margin: 0px 20px;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    flex: 1;
}

.container__nav li,
.container__nav-full li {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 5px 15px;
    /* width: 115px; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    margin-left: 5px;
}

.container__nav li:hover,
.container__nav-full li:hover {
    background-color: #ffffff;
    color: #101941;
    border-radius: 10px;
}

.container__nav li.active,
.container__nav-full li.active {
    background-color: #ffffff;
    color: #101941;
    border-radius: 10px;
}

.container__nav li > img,
.container__nav-full li > img {
    height: 2.4rem;
}

.container__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    overflow-x: auto;
    height: calc(100vh - 90px);
}

.nav-icon {
    font-size: 1.5rem;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    margin-bottom: 2px;
}

.temp__element {
    border-radius: 0.5rem;
    border: 1px solid #101941;
    height: 100px;
    flex: 1;
    min-width: 150px;
    padding: 10px;
    text-align: center;
    margin: 10px;
}

.temp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
    border-radius: 5px;
    outline: none;
    line-height: 1;
    margin-bottom: 10px;
    width: -webkit-fill-available;
}

select option {
    outline: none;
}
