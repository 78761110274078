@import url(../../css/Container.css);
@import url(../../css/Common.css);

.logo__image {
    height: 35px;
}

.header__sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
}

.CmsHeader__wrapper {
    display: flex;
    justify-content: space-evenly;
}

.padding20 {
    padding: 20px;
}

.CmsHeader__right {
    display: flex;
    align-items: center;
}

.CmsHeader__left {
    display: flex;
    flex: 1;
}

.search__view {
    display: flex;
    /* min-width: 250px; */
    height: 40px;
    background: transparent;
    border: 1px solid #d7dbea;
    box-sizing: border-box;
    border-radius: 10px;
    outline: 0;
    align-items: center;
    margin-right: 10px;
    padding-left: 10px;
}

.search__view > input {
    outline: 0;
    background-color: transparent;
    border: none;
    display: flex;
    flex: 1;
    padding: 5px 0 5px 14px;
    height: 25px;
    color: #101941;
}

.search__view > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #203594;
    opacity: 0.5;
    /* Firefox */
}

.search_icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    color: rgba(32, 53, 148, 0.5);
}

.CmsHeader__profile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
}

.CmsHeader__profileImage {
    height: 40px;
    object-fit: contain;
    margin-left: 10px;
    border-radius: 10px;
}

.profile__name {
    font-size: 12px;
    color: #203594;
    font-weight: bold;
}

.profile__role {
    font-size: 10px;
    color: #203594;
    opacity: 0.7;
}

.profile__expand {
    height: 7px;
    margin-left: 10px;
}

.recharts-legend-item-text,
tspan {
    font-size: 10px;
}

.text-btn {
    background: transparent !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #203594 !important;
    text-align: right !important;
    font-size: 10px !important;
    color: #203594 !important;
    opacity: 0.7 !important;
    font-family: MarkPro, sans-serif !important;
}

th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px;
    font-size: 12px;
    border: none;
    font-weight: 500;
    min-height: 33px;
    height: 33px;
    max-height: fit-content;
}

td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px;
    font-size: 12px;
    border: none;
    font-weight: 400;
    min-height: 33px;
    height: 33px;
    max-height: fit-content;
    min-width: 60px;
    max-width: 150px !important;
    word-break: break-word;
    word-wrap: break-word;
}

/* td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 20px;
    margin: 5px 0px;
    font-size: 11px;
    border: none;
    font-weight: 500;
} */

.td-wrapper {
    white-space: break-spaces;
    max-width: 197px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.header {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    background-color: #101941;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    height: 70px;
    padding-right: 25px;
    padding-left: 10px;
    min-width: fit-content;
}

.avatar {
    background-color: white;
    color: #101941;
    cursor: pointer;
}

.modal_avatar {
    background-color: #101941;
    color: #ffffff;
}

.header nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.header nav li a {
    color: #b7bac6 !important;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.header nav li a.active,
.header nav li a:hover {
    color: #ffffff !important;
    font-weight: 700;
}

.header nav img {
    height: 20px;
}

.avatar_container {
    position: relative;
}

.profile_modal {
    position: absolute;
    background: white;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08), -2px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    right: -20%;
    margin-top: 10px;
    min-width: 200px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-transform: capitalize;
    gap: 5px;
}

.profile_modal_avatar {
    color: white;
    background-color: #101941;
    margin-left: 10;
}

.profile_modal_name {
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    margin-top: 8px;
}

.profile_modal_role {
    margin: 3px 0;
}

.profile_modal_company {
    color: #70758d;
    text-transform: capitalize;
}

.profile_modal_action_container {
    font-size: 12px;
    text-align: left;
    width: 100%;
}

.action {
    cursor: pointer;
    color: #70758d !important;
    width: calc(100% - 16px);
    padding: 8px;
}

.action:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.profile_modal_edit {
    color: inherit;
    display: block;
}

hr {
    margin: 15px 0;
    width: 100%;
    border: 0.7px solid #e8e8ec;
}
.pointer {
    position: absolute;
    top: -8px;
    right: 8%;
    border-bottom: 10px solid white;
    border-left: 6px solid rgba(0, 0, 0, 0);
    border-right: 6px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    height: 0;
    vertical-align: top;
    width: 0;
}

.profile_modal_name,
.profile_modal_role,
.profile_modal_company {
    width: fit-content;
    text-align: center;
}
